import React from 'react'
import { About, ServiceTypes, Services, Statistics, Work } from '../components'

const ServicePage = () => {
  return (
    <div className='md:pt-[160px] pt-[110px]'>
      <ServiceTypes />
      <Services />
      <About />
      <Statistics />
      <Work />
    </div>
  )
}

export default ServicePage