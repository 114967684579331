import React from 'react'
import { projectImage1, projectImage2 } from '../assets'
import { useNavigate } from 'react-router-dom'


const Projects = () => {

  const navigate = useNavigate()

  return (
    <div className='md:py-16 py-10 md:px-[100px] px-4'>
      <h2 className="md:text-3xl text-xl font-bold">
        Types of projects we work on
      </h2>
      <div className="md:pt-10 pt-6 pb-10 grid md:grid-cols-2 grid-cols-1 md:gap-[60px] gap-10">
        <div className="rounded-xl">
          <img src={projectImage1} alt='' className='h-[190px] md:h-[250px] rounded-t-xl' width='' height='' />
          <div className="border-t-4 border-black bg-primary md:p-10 p-4 flex items-center justify-between text-white rounded-b-xl">
            <div className="flex flex-col">
              <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
                Small Scale Projects  
              </h3>
              <span className="font-normal md:text-base text-xs">
                We're your go-to company
              </span>
            </div>
          </div>
        </div>
        <div className="rounded-xl">
          <img src={projectImage2} alt='' className='h-[190px] md:h-[250px] rounded-t-xl' width='' height='' />
          <div className="border-t-4 border-black bg-primary md:p-10 p-4 flex items-center justify-between text-white rounded-b-xl">
            <div className="flex flex-col">
              <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
                Large Scale Projects 
              </h3>
              <span className="font-normal md:text-base text-xs">
                We thrive on this too..
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <button
          className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary"
          onClick={() => navigate('/contact')}
        >
          Contact Us
        </button>
      </div>
    </div>
  )
}

export default Projects