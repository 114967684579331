import React from 'react'
import { fiveStars, heroBg, multipleDps } from '../assets'
import { useNavigate } from 'react-router-dom'

const HeroSection = () => {

  const navigate = useNavigate()

  const styles = {
    backgroundImage: `url(${heroBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div style={styles} className='h-[100vh] md:pt-[80px]'>
      <div className="md:w-1/2 flex flex-col justify-center h-full md:pl-[100px] pl-4">
        {/* <p className="font-bold md:mb-4 mb-1 text-primary">
          Worksites Recruitment Limited
        </p> */}
        <h1 className="md:text-6xl text-4xl font-bold mb-4 leading-tight">
          Find The Perfect Workforce Fit..
        </h1>
        <p className="md:w-[68%] w-[95%] mb-6">
          Are you looking to work on a construction project with top-tier construction companies? Look no further and join us today.
        </p>
        <div className="flex items-center gap-4 mb-6">
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary">
            Join Us
          </a>
          <button 
            className="md:py-3 py-2 md:px-12 px-6 text-black bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </button>
        </div>
        <div className="flex items-center gap-4">
          <img src={multipleDps} alt="" className="h-12" />
          <div className="">
            <span className="block text-sm">
              "Best choice I've made"
            </span>
            <span className="block text-xs font-bold">
              - Wale Smith, CEO compass Inc.
            </span>
            <img src={fiveStars} alt="" className="h-3 mt-1" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection