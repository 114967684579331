import React from 'react'
import { Contact } from '../components'

const ContactPage = () => {
  return (
    <div className='md:pt-[140px] pt-[110px]'>
      <Contact />
    </div>
  )
}

export default ContactPage