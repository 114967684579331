import { projectIcon, ratingIcon, satisfactionIcon } from "../assets";

export const navLinks = [
  {
    id: 1,
    linkName: 'Home',
    path: '/'
  },
  {
    id: 2,
    linkName: 'Services',
    path: '/services'
  },
  {
    id: 3,
    linkName: 'About',
    path: '/about'
  },
  {
    id: 4,
    linkName: 'Contact',
    path: '/contact'
  },
];

export const statistics = [
  {
    id: 1,
    stat: '100%',
    statDescription: 'Client Oriented',
    statImage: projectIcon,
  },
  {
    id: 2,
    stat: 'Very',
    statDescription: 'Professional',
    statImage: satisfactionIcon,
  },
  {
    id: 3,
    stat: '100%',
    statDescription: 'Clients Satisfaction',
    statImage: ratingIcon,
  },
]

export const customers = [
  {
    id: 1,
    customerName: 'Dr. Thoyinstar, CEO of Compass Inc',
    summary: "Working with Worksite Recruitment Limited was an absolute pleasure. Their team of experts were able to take our vision and turn it into reality, which has helped us grow our business. We couldn't be happier working with them!"
  },
  {
    id: 2,
    customerName: 'Dr. DoyinMoon, CEO of Mathset Inc',
    summary: "Working with Worksite Recruitment Limited was an absolute pleasure. Their team of designers and developers were able to take our vision and turn it into a stunning website that has helped us attract new customers and grow our business. We couldn't be happier with the results!"
  },
]