import React from 'react'
import { About, Customers, Services, Statistics, Work } from '../components'

const AboutPage = () => {
  return (
    <div className='md:pt-[90px] pt-[70px]'>
      <About />
      <Statistics />
      <Customers />
      <Services />
      <Work />
    </div>
  )
}

export default AboutPage