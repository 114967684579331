import React from 'react'
import { About, Customers, HeroSection, Projects, Services, Statistics, Work } from '../components'

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <Statistics />
      <Services />
      <About />
      <Projects />
      <Customers />
      <Work />
    </div>
  )
}

export default HomePage