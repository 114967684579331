import React from 'react'
import { commercialImage, industrialImage, infrastructuralImage, residentialImage } from '../assets'
import { useNavigate } from 'react-router-dom'

const ServiceTypes = () => {

  const navigate = useNavigate()

  return (
    <div className='md:mb-[60px] mb-10 md:px-[100px] px-4'>
      <div className="flex flex-col items-center text-center md:mb-[60px] mb-10">
        <h2 className="md:text-3xl text-xl font-bold mb-4">
          Services we offer
        </h2>
        <p className="md:w-[50%] w-full">
          At Worksites Recruitment, we redefine construction workforce recruitment with a commitment to excellence and a passion for building success.
        </p>
      </div>
      <div className="flex flex-row justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Residential Building construction 
          </h3>
          <p className="md:w-[85%] w-full mb-8">
            We aim to provide you with valuable and experienced workers for all your residential construction projects within United Kingdom. Contact us, for your diverse requirements in construction workers and we will be glad to serve you with best hands.
          </p>
          <div className="">
            <button 
              onClick={() => navigate('/contact')}
              className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary">
              Contact Us
            </button>
          </div>
        </div>
        <img src={residentialImage} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row-reverse justify-between mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Commercial Building construction 
          </h3>
          <p className="md:w-[85%] w-full mb-8">
            We know how dynamic and complex the commercial construction is in United Kingdom. For this reason, we are aware of the need of workers who would bring onboard exceptional skills, experience, and attention to details. Bearing this in mind, we usually take time to seek for seasoned workers who are ready to join your team to deliver nothing but excellence.
          </p>
          <div className="">
            <button 
              onClick={() => navigate('/contact')}
              className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary">
              Contact Us
            </button>
          </div>
        </div>
        <img src={commercialImage} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Infrastructural Projects 
          </h3>
          <p className="md:w-[85%] w-full mb-8">
            Good infrastructure is one of the major bedrocks of an economically stable society. We know this at Worksites Recruitment, and the importance of delivering a quality infrastructure project as well as maintaining it. We are happy to collaborate by providing skilled and experienced workers to achieve this goal.
          </p>
          <div className="">
            <button 
              onClick={() => navigate('/contact')}
              className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary">
              Contact Us
            </button>
          </div>
        </div>
        <img src={infrastructuralImage} alt="" className="w-1/2 md:block hidden" />
      </div>
      <div className="flex flex-row-reverse justify-between bg-[#F3F3F3] mb-10">
        <div className="flex flex-col justify-center md:pl-[60px] md:py-0 px-6 py-10">
          <h3 className="md:text-2xl text-base font-bold flex items-center gap-2 md:mb-2 mb-1">
            Industrial projects
          </h3>
          <p className="md:w-[85%] w-full mb-8">
            Constant supply of goods, services and products is a result of a functional facilities. We know to achieve this, requires a joint effort of an experienced construction team with a good understanding of the goal and the need to work towards achieving same. We are happy to collaborate with you by ensuring such workers are made available for your projects.
          </p>
          <div className="">
            <button 
              onClick={() => navigate('/contact')}
              className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary">
              Contact Us
            </button>
          </div>
        </div>
        <img src={industrialImage} alt="" className="w-1/2 md:block hidden" />
      </div>
    </div>
  )
}

export default ServiceTypes