import './App.css';
import { Routes, Route } from 'react-router-dom'
import { Navbar, Footer } from './components';
import { AboutPage, ContactPage, CopyrightPage, HomePage, PrivacyPage, ServicePage, TermsPage } from './pages';
import ScrollToTop from './hoc/ScrollToTop';

function App() {
  return (
    <div className="">
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route path='/' element={ <HomePage /> } />
          <Route path='/services' element={ <ServicePage /> } />
          <Route path='/about' element={ <AboutPage /> } />
          <Route path='/contact' element={ <ContactPage /> } />
          <Route path='/copyright' element={ <CopyrightPage /> } />
          <Route path='/terms-and-conditions' element={ <TermsPage /> } />
          <Route path='/privacy-policy' element={ <PrivacyPage /> } />
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
