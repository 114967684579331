import React, { useState } from 'react'
import { customerImage, nextIcon, previousIcon } from '../assets'
import { customers } from '../constants'

const Customers = () => {
  const [current, setCurrent] = useState(0)

  return (
    <div className='mb-16 flex items-center w-full'>
      <div className="md:w-1/2 bg-primary text-white md:p-[100px] px-4 py-16 flex flex-col justify-center md:h-[600px]">
        <h2 className="md:text-3xl text-xl font-bold mb-6">
          Why Our Customers Love Working with Us
        </h2>
        <p className="mb-6">
          "{customers[current].summary}"
        </p>
        <p className="mb-8">
          - {customers[current].customerName}.
        </p>
        <div className="flex items-center gap-4">
          <div 
            className={`flex items-center justify-center w-12 h-12 border-2 border-black bg-white rounded-lg ${current > 0 ? 'cursor-pointer' : 'cursor-default'}`}
            onClick={() => { current > 0 && setCurrent(prev => prev - 1) }}
          >
            <img src={previousIcon} alt="" className="w-4 h-4" />
          </div>
          <div 
            className={`flex items-center justify-center w-12 h-12 border-2 border-black bg-white rounded-lg ${current < customers.length - 1 ? 'cursor-pointer' : 'cursor-default'}`}
            onClick={() => { current < customers.length - 1 && setCurrent(prev => prev + 1) }}
          >
            <img src={nextIcon} alt="" className="w-4 h-4" />
          </div>
        </div>
      </div>
      <img src={customerImage} alt="" className="md:block hidden w-1/2 h-[600px]" />
      {/* <div className="w-1/2">
      </div> */}
    </div>
  )
}

export default Customers